



































import { Vue, Component, Watch } from "vue-property-decorator";
import { UserModule } from "@/model/user";
import { ElForm } from "element-ui/types/form";

@Component
export default class extends Vue {
  rules = {
    account: [
      {
        required: true,
        message: "请输入手机号",
        trigger: "blur",
      },
      { min: 5, message: "长度不少于5个字符", trigger: "blur" },
    ],
    password: [
      { required: true, message: "请输入密码", trigger: "blur" },
      { min: 5, message: "长度不少于6个字符", trigger: "blur" },
    ],
  };
  info = {
    account: "",
    password: "",
  };
  async submit() {
    try {
        // 验证表单...
        await (this.$refs["form"] as ElForm | undefined)?.validate();

        // 调用异步操作
        await UserModule.login(this.info); // 如果使用 dispatch 调用 action

        // 成功处理逻辑
        this.$router.push("/");
      } catch (error) {
        console.error("An error occurred during the login process:", error);
        // 错误处理逻辑，例如显示错误消息
      }
  }

  created() {
    if (UserModule.token !== null) {
      this.$router.push("/");
    }
  }
}
